<template>
  <div :class="['navbar-default', 'Ctrldemo', { header: istop, nheader: !istop }]" id="indexHeader" ref="headertotop">
    <div class="navbar-header coker">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
        aria-expanded="false" aria-controls="navbar" ref="btn_list">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <router-link class="navbar-brand headerTitle" to="/">
        <h1>JiHua的web和js开发数据</h1>
      </router-link>
      <a href="https://jihau.top"><img src="https://www.jihau.top/img/logo.png" class="logo" alt="logo" /></a>
    </div>
    <div id="navbar" class="navbar-collapse collapse Ctrldemo" ref="menubox">
      <ul class="nav navbar-nav">
        <li @click="closeMenu">
          <router-link to="/Notify">通知</router-link>
        </li>
        <li @click="closeMenu">
          <router-link to="/DevProcess">发展历程</router-link>
        </li>
        <li @click="closeMenu">
          <router-link to="/SpsList">友链</router-link>
        </li>
        <li class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
            @mousemove.once="listMenu" ref="more_something">更多<span class="caret"></span></a>
          <ul class="dropdown-menu" ref="dropdown_menu">
            <li>
              <router-link to="/checkver"><span @click="closeMenu">激活账户</span></router-link>
            </li>
            <li><a href="https://www.jihau.com">主站博客页面</a></li>
            <li>
              <router-link to="/page/YSZC"><span @click="closeMenu">隐私政策</span></router-link>
            </li>
            <li>
              <router-link to="/html/goods/10010.html"><span @click="closeMenu">物品寻求页面</span></router-link>
            </li>
            <li><a href="https://jihau.com/POP/">测试</a></li>
          </ul>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right right_btn">
        <li @click="closeMenu">
          <router-link to="/Search">
            <span class="glyphicon glyphicon-search" style="font-size: 20px" @click="closeMenu"></span>
          </router-link>
        </li>
        <li @click="closeMenu" class="UserNL" v-if="JSON.stringify(this.$store.state.Userdata) != '{}'">
          <img :src="this.$store.state.Userdata.Users.user_pic" class="author_logo" alt="logo" />
          <router-link to="/CtrlView">{{ this.$store.state.Userdata.Users.username }}</router-link>
        </li>
        <li class="HeaderbtnArea">
          <button @click="login" class="btn" v-if="!token">登录</button>
          <button @click="outlogin" class="btn" v-else>退出登录 </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import GetUData from '@/API/Ctrl_menuAPI/UserData'
export default {
  props: [],
  data () {
    return {
      istop: false,
      token: localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== 'undefined'
    }
  },
  mounted () {
    window.addEventListener('scroll', this.HeaderTop)
  },
  created () {
    if (JSON.stringify(this.$store.state.Userdata) === '{}' && localStorage.getItem('token') !== null) {
      this.getUsersdata()
    }
  },
  // 方法
  methods: {
    listMenu () {
      // eslint-disable-next-line camelcase
      const more_site = this.$refs.more_something
      // eslint-disable-next-line camelcase
      const more_site_son = this.$refs.dropdown_menu
      more_site.addEventListener('mouseenter', function () {
        more_site.setAttribute('aria-expanded', 'ture')
        // eslint-disable-next-line no-global-assign
        open = more_site.parentNode
        open.className = 'dropdown open'
      })
      more_site.addEventListener('mouseleave', function () {
        more_site.setAttribute('aria-expanded', 'flase')
        // eslint-disable-next-line no-global-assign
        open = more_site.parentNode
        open.className = 'dropdown'
      })
      more_site_son.addEventListener('mouseenter', function () {
        more_site.setAttribute('aria-expanded', 'ture')
        // eslint-disable-next-line no-global-assign
        open = more_site.parentNode
        open.className = 'dropdown open'
      })
      more_site_son.addEventListener('mouseleave', function () {
        more_site.setAttribute('aria-expanded', 'flase')
        // eslint-disable-next-line no-global-assign
        open = more_site.parentNode
        open.className = 'dropdown'
      })
    },
    closeMenu () {
      const btnList = this.$refs.btn_list
      const menubox = this.$refs.menubox
      const moresite = this.$refs.more_something
      if (btnList && menubox && moresite) {
        btnList.setAttribute('aria-expanded', 'flase')
        moresite.setAttribute('aria-expanded', 'flase')
        btnList.setAttribute('class', 'navbar-toggle collapsed')
        menubox.setAttribute('class', 'navbar-collapse collapse')
      }
    },
    HeaderTop () {
      const indexHeader = this.$refs.headertotop
      if (indexHeader) {
        const scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop
        if (scrollTop >= 10) {
          this.istop = true
        }
        if (scrollTop === 0) {
          this.istop = false
        }
      }
    },
    login () {
      this.closeMenu()
      if (this.token) {
        this.$router.push('/CtrlView')
      } else {
        this.$router.push('/Login')
      }
    },
    outlogin () {
      this.closeMenu()
      localStorage.removeItem('token')
      localStorage.removeItem('Username')
      localStorage.removeItem('Useridentity')
      localStorage.removeItem('UserData')
      this.$store.commit('cagUserData', [])
      location.reload()
    },
    async getUsersdata () {
      if (localStorage.getItem('Username') !== null) {
        const { data: res } = await GetUData.GetUserData()
        this.$store.commit('cagUserData', res.data)
      }
    }
  },
  name: 'HeaderM'
}
</script>

<style lang="less" scoped>
#navbar {
  font-size: 1.5rem;
  font-weight: bolder;
  background-color: rgba(255, 255, 255, 0);
}

#indexHeader {
  max-width: 1200px;
}

.UserNL {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

}

.author_logo {
  width: 30px;
  height: 30px;
}

@media only screen and (min-width: 755px) {
  #indexHeader {
    transition: all 0.5s;
  }

  .navbar-header {
    float: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 35px;
    height: 30px;
    margin: 15px 0 10px 0;
  }

  .coker>a>h1 {
    font-size: 3rem;
    padding: 0;
    margin: 0;
  }

  .right_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .nheader {
    width: 98vw;
    margin: 0 auto;
  }

  .header {
    position: fixed;
    top: 0;
    z-index: 999;
    height: 50px;
    left: 5.5vw;
    max-width: 100vw;
    min-width: 89vw;
  }
}

// 移动端样式
@media only screen and (max-width: 755px) {
  #indexHeader {
    position: fixed;
    width: 100vw;
    z-index: 100;
    top: 0;
    left: 0;
  }

  .logo {
    width: 35px;
    height: 27px;
    margin: 12px 0;
  }

  .headerTitle {
    padding: 10px;
  }

  .headerTitle>h1 {
    font-size: 2.5rem;
    padding: 0;
    margin: 5px 0 0 0;
  }

  #navbar {
    position: absolute;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
  }

  .header {
    position: fixed;
    top: -1px;
    left: 0;
    z-index: 999;
    height: 50px;
    width: 100vw;
  }

  .HeaderbtnArea {
    text-align: right;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 370px) {
  .coker>a>h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 280px) {
  .logo {
    display: none;
  }

  .coker>a>h1 {
    font-size: 1.6rem;
  }
}
</style>

<template>
  <div id="" class="cagArticle">
    <div class="btn">
      <van-button @click="getimg">获取图库</van-button>
      <van-button color="#1989FA" size="small" @click="saveArticle">确认发布</van-button>
      <van-button type="primary" class="HeaderItem" size="small" @click="startEditiPost">开始水文章</van-button>
      <van-button type="danger" class="HeaderItem" size="small" @click="StopEditiPost">关闭水文章</van-button>
    </div>
    <div class="cagArea">
      <div class="astate" @click="cagastate">
        <i :class="{
          'glyphicon glyphicon-chevron-left': !isChange,
          'glyphicon glyphicon-resize-horizontal': isChange,
        }"></i>
      </div>
      <aside :class="{
        cagAside: !isChange,
        opAside: isChange,
      }">
        <h1 class="title">
          标题:<input type="text" v-model="newArticleData.title" class="form-control" />
        </h1>
        <h4>
          标签:<input type="text" v-model="newArticleData.lable" class="form-control" />
        </h4>
        <h4>
          关键词:<input type="text" v-model="newArticleData.keyword" class="form-control" />
        </h4>
        <h4>
          封面：
          <input type="text" v-model="newArticleData.cover_img" class="imgurl form-control" />
          预览
          <img :src="newArticleData.cover_img" alt="文章封面" class="pvimg" />
        </h4>
        <p>⚠ 图片嵌入 需要点击获取图片才能查阅已经上传的本账户内的图片</p>
      </aside>
      <div class="cagcontent">
        <ckeditor v-model="newArticleData.content" :config="editorConfig" :editor-url="editorUrl"></ckeditor>
      </div>
    </div>
    <gallery ref="imageM"></gallery>
  </div>
</template>

<script>
import setArticle from '@/API/Ctrl_menuAPI/ArticleAPI'
import gallery from '@/components/Ctrl_menu/gallery.vue'

export default {
  components: { gallery },
  props: [],
  data () {
    return {
      Article: {
        img: []
      },
      newArticleData: {
        username: localStorage.getItem('Username'),
        title: '',
        lable: '',
        keyword: '',
        cover_img: '',
        content: '',
        OpenEditiPost: 0
      },
      isChange: false,
      editorConfig: {
        toolbarGroups: [
          { name: 'document', groups: ['mode', 'document', 'doctools'] },
          { name: 'clipboard', groups: ['clipboard', 'undo'] },
          { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
          { name: 'forms', groups: ['forms'] },
          '/',
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
          { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
          { name: 'links', groups: ['links'] },
          { name: 'insert', groups: ['insert'] },
          '/',
          { name: 'styles', groups: ['styles'] },
          { name: 'colors', groups: ['colors'] },
          { name: 'tools', groups: ['tools'] },
          { name: 'others', groups: ['others'] },
          { name: 'about', groups: ['about'] }
        ],
        removeButtons: 'Print,Templates,NewPage,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,Language,Iframe',
        // 编辑器设定.
        height: 500,
        skin: 'moono-lisa',
        extraPlugins: ['quicktable', 'codesnippet', 'button'],
        removePlugins: 'easyimage,cloudservices,exportpdf',
        codeSnippet_theme: 'monokai_sublime'
      },
      editorUrl: '../ckeditor/ckeditor.js',
      isOpenimg: false,
      rules: {
        username: {
          rule: /\S/,
          msg: '用户名不能为空'
        },
        title: {
          rule: /\S/,
          msg: '文章标题不能为空'
        },
        content: {
          rule: /\S/,
          msg: '不能发布空白文章哦！'
        },
        lable: {
          rule: /\S/,
          msg: '忘记填标签咯！'
        },
        keyword: {
          rule: /\S/,
          msg: '填一下关键字吧！'
        }
      }
    }
  },
  // 方法
  methods: {
    cagastate () {
      this.isChange = !this.isChange
    },
    async saveArticle () {
      if (this.validata('username')) {
        if (this.validata('title')) {
          if (this.validata('lable')) {
            if (this.validata('keyword')) {
              if (this.validata('content')) {
                if (this.newArticleData.content.length <= 120) {
                  this.$toast({
                    message: '字数少于120，不能水文章哟!',
                    position: 'top'
                  })
                } else {
                  const { data: res } = await setArticle.UseraddArticle(
                    this.newArticleData
                  )
                  if (res.status === 200) {
                    this.$toast({
                      message: res.message,
                      position: 'top'
                    })
                    this.$router.push('/article/' + res.article)
                  } else {
                    this.$toast({
                      message: res.message,
                      position: 'top'
                    })
                  }
                }
              }
            }
          }
        }
      }
    },
    async getimg () {
      this.$refs.imageM.toge()
    },
    // 随机生成中文字
    generateChineseCharacters (Num) {
      let characters = ''
      const totalCharacters = Num
      while (characters.length < totalCharacters) {
        const randomUnicode = Math.floor(Math.random() * (0x9fa5 - 0x4e00 + 1)) + 0x4e00
        characters += String.fromCharCode(randomUnicode)
      }
      return characters
    },
    // 开始刷文章
    startEditiPost () {
      this.OpenEditiPost = setInterval(async () => {
        this.newArticleData.username = localStorage.getItem('Username')
        this.newArticleData.title = this.generateChineseCharacters(10)
        this.newArticleData.lable = this.generateChineseCharacters(10)
        this.newArticleData.keyword = this.generateChineseCharacters(15)
        this.newArticleData.content = this.generateChineseCharacters(450)
        const { data: res } = await setArticle.UseraddArticle(this.newArticleData)
        if (res.status === 200) {
          this.newArticleData = {
            title: '',
            lable: '',
            keyword: '',
            content: ''
          }
        }
      }, 800)
    },
    // 停止刷文章
    StopEditiPost () {
      clearInterval(this.OpenEditiPost)
      this.newArticleData = {
        title: '',
        lable: '',
        keyword: '',
        content: ''
      }
    },
    validata (key) {
      let bool = true
      if (!this.rules[key].rule.test(this.newArticleData[key])) {
        this.$toast({
          message: this.rules[key].msg,
          position: 'top'
        })
        bool = false
        return false
      }
      return bool
    }
  },
  name: 'addArticle'
}
</script>

<style lang="less" scoped>
@media only screen and (min-width: 755px) {
  .cagArea {
    display: flex;
    position: relative;
  }

  .cagAside {
    max-width: 16vw;
  }

  .imgarea {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 500px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: scroll;

    .atitle {
      font-size: 3rem;
      font-weight: bolder;
      border-bottom: 2px gray solid;
    }
  }

  .kuimgarea {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .imgcopy {
      display: flex;
      flex-direction: column;
    }

    .kuimg {
      width: 100px;
      height: 100px;
      margin: 5px;
    }
  }
}

@media only screen and (max-width: 755px) {
  .cagArea {
    position: relative;
  }

  .imgarea {
    position: absolute;
    top: 22%;
    left: 33%;
    transform: translate(-30%, -30%);
    width: 365px;
    height: 500px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: scroll;

    .atitle {
      font-size: 3rem;
      font-weight: bolder;
      border-bottom: 2px gray solid;
    }
  }

  .kuimgarea {
    display: flex;
    overflow: scroll;
    width: 100%;

    .imgcopy {
      display: flex;
      flex-direction: column;
    }

    .kuimg {
      width: 100px;
      height: 100px;
      margin: 5px;
    }
  }
}

.cagAside {
  flex: 0.5;
  background-color: rgb(5, 0, 105);
  color: white;
  padding: 5px;
  position: relative;
  overflow: hidden;
  transform: scaleY(1);
  transition: transform 0.5s;
  transform-origin: top center;
}

.opAside {
  transform: scaleY(0);
  transition: transform 0.2s;
  transform-origin: top center;
  width: 0;
}

.cagcontent {
  flex: 2;
  background-color: rgb(209, 184, 184);
}

.astate {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 3rem;
}

.btn {
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

.imgurl {
  color: black;
}

.pvimg {
  width: 80%;
  max-height: 100px;
}
</style>

import { render, staticRenderFns } from "./DevP_cagPanel.vue?vue&type=template&id=630acb97&scoped=true&"
import script from "./DevP_cagPanel.vue?vue&type=script&lang=js&"
export * from "./DevP_cagPanel.vue?vue&type=script&lang=js&"
import style0 from "./DevP_cagPanel.vue?vue&type=style&index=0&id=630acb97&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630acb97",
  null
  
)

export default component.exports
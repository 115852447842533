import { render, staticRenderFns } from "./ContentArticle.vue?vue&type=template&id=4d851965&scoped=true&"
import script from "./ContentArticle.vue?vue&type=script&lang=js&"
export * from "./ContentArticle.vue?vue&type=script&lang=js&"
import style0 from "./ContentArticle.vue?vue&type=style&index=0&id=4d851965&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d851965",
  null
  
)

export default component.exports